/* Fonts */

@font-face {
    font-family: "Handmade";
    src: url("../fonts/handmade.woff2");
}

@font-face {
    font-family: "Grand-hotel";
    src: url("../fonts/grand-hotel.woff2");
}

@font-face {
    font-family: "Amatic";
    src: url("../fonts/amatic.woff2");
}


/* ScrollBar */

::-webkit-scrollbar {
    width: 18px;
}

::-webkit-scrollbar-track {
    background: #DDD1B5;
}

::-webkit-scrollbar-thumb {
    z-index: 99;
    background-color: #6C4A3499;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    opacity: 0.7;
}

.active {
    font-weight: 900;
}

/* All */

body {
    position: relative;
    scroll-behavior: smooth;
    left: 2em;
    width: 90vw;
    height: 100vh;
    background: #e8e4dc;
    background-attachment: fixed;
}

/* Header */

.header-logo {
    height: 9em;
    border-radius: 20em;
    border: #6C4A34 solid 3px;
    margin: 1em;
}

.header-logo:hover {
    opacity: 0.7;

}

.header-link {
    position: relative;
    text-decoration: none;
    font-family: "Handmade";
    left: 34em;
    font-size: 1.5em;
    padding: 1.5em;
    color: #A64A93;
}

.header-link:hover {
    opacity: 0.7;
    text-decoration: underline;
}

/* Inspiration Page */

.title-inspirations {
    position: relative;
    font-size: 2em;
    margin-top: 0.5em;
    left: 1em;
    font-family: "Grand-hotel";
    color: #D788C3;
    text-decoration: #D788C3 underline;
}

.my-inspirations {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inspiration {
    display: flex;
    margin: 2.2em;
    border-radius: 1em;
    height: 22em;
    border: 4px solid #DDD1B5;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Inspiration page in responsive */

@media (max-width: 665px) {
    .inspiration {
        height: 35em;
    }
}

@media (max-width: 446px) {

    .title-inspirations {
        font-size: 1.6em;
    }

    .my-inspirations {
        margin-left: -0.8em;
    }
}

@media (max-width: 422px) {

    .title-inspirations {
        font-size: 1.6em;
    }

    .inspiration {
        height: 28em;
    }

    .title-inspirations {
        font-size: 1.6em;
    }

    .my-inspirations {
        margin-left: -1.2em;
    }
}

@media (max-width: 370px) {
    .title-inspirations {
        font-size: 1.4em;
    }

    .my-inspirations {
        margin-left: -2em;
    }
}