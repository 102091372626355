/* Fonts */

@font-face {
    font-family: "Handmade";
    src: url("../fonts/handmade.woff2");
}

@font-face {
    font-family: "Grand-hotel";
    src: url("../fonts/grand-hotel.woff2");
}

@font-face {
    font-family: "Amatic";
    src: url("../fonts/amatic.woff2");
}

/* ScrollBar */

::-webkit-scrollbar {
    width: 18px;
}

::-webkit-scrollbar-track {
    background: #DDD1B5;
}

::-webkit-scrollbar-thumb {
    z-index: 99;
    background-color: #6C4A3499;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    opacity: 0.7;
}

.active {
    font-weight: 900;
}

/* All */

body {
    position: relative;
    scroll-behavior: smooth;
    left: 2em;
    width: 90vw;
    height: 100vh;
    background: #e8e4dc;
    background-attachment: fixed;
}

/* Header */

.header-logo {
    height: 10em;
    border-radius: 20em;
    border: #6C4A34 solid 3px;
    margin: 1em;
}

.header-link {
    position: relative;
    text-decoration: none;
    font-family: "Handmade";
    left: 34em;
    font-size: 1.5em;
    padding: 1.5em;
    color: #A64A93;
}

.header-link:hover {
    opacity: 0.7;
    text-decoration: underline;
}

/* Hacks page */

.title-hacks {
    position: relative;
    font-size: 2em;
    margin-top: 0.5em;
    left: 1em;
    font-family: "Grand-hotel";
    color: #D788C3;
    text-decoration: #D788C3 underline;
}

.generate-image {
    width: 10em;
    border-radius: 1.5em;
}

.hacks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.my-hacks {
    display: inline-block;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    background-color: #A64A93;
    padding: 0.6em;
    margin: 1em;
    width: 16em;
    height: 20em;
    border: 4px solid #D788C3;
    font-size: 1.2em;
    border-radius: 1.6em;
    font-family: "Handmade";
    color: #DDD1B5;
    transition: transform 0.4s;
}


/* Hacks page in responsive */

@media (max-width: 770px) {
    .my-hacks {
        width: 14em;
        height: 20em;
        font-size: 1em;
    }
}

@media (max-width: 568px) {
    .my-hacks {
        width: 20em;
    }
}


@media (max-width: 446px) {

    .title-hacks {
        font-size: 1.6em;
    }

    .my-hacks {
        margin-left: -0.6em;
    }
}

@media (max-width: 400px) {
    .title-hacks {
        font-size: 1.6em;
    }
}

@media (max-width: 370px) {
    .title-hacks {
        font-size: 1.4em;
    }

    .my-hacks {
        width: 18em;
    }
}